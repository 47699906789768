import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGoogleMaps from "@fawmi/vue-google-maps";
const app = createApp(App);
app
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyA_v6WgXlHfs7pDEN9KtgcsPaPnIOZv4Yg",
      libraries: "places",
    },
  })
  .use(router)
  .mount("#app");
