import { createRouter, createWebHistory } from "vue-router";
import MarkPlace from "./views/mark-place.vue";
import ShowRoute from "./views/show-route.vue";

const routes = [
  {
    name: "markPlace",
    path: "/mark-place",
    component: MarkPlace,
  },
  {
    name: "showRoute",
    path: "/show-route",
    component: ShowRoute,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
