<template>
    <keep-alive>
    <router-view></router-view>
    </keep-alive>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>