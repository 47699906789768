<template>
    <div id="container">
        <h2>This is an example of tracing and updating route</h2>
        <GMapMap :center="center"
        :zoom="12"
        disableDefaultUI="true"
        :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: [
          {
            featureType: 'all',
            elementType: 'geometry',
            stylers: [{ color: '#d7d7d7' }], // Set the background color here (blue in this case)
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#FFFFFF',weight:3,gama:0.5,saturation: -50 }] // Set the road color here (blue in this case)
          },
          {
            featureType: 'label',
            elementType: 'labels.text.fill',
            stylers: [
              { visibility: 'off' }    // Hide text labels
            ]
          },
          
        ],
      }"
        class="map-body">
        <GMapMarker
        v-for="(place, index) in Markers"
        :key="index"
        :position="place.coordinates"
        :clickable="false"
      :draggable="false"
      :icon="{
        url:require('../assets/driver-icon.png'),
        scaledSize: {width: 35, height: 65},
    }"
      ></GMapMarker>
        <DirectionsRenderer
        travelMode="DRIVING"
        :origin="startLocation"
        :destination="endLocation"
      />
    </GMapMap>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            center:{lat: 51.193048, lng: 6.642120},
            markers: [
        { coordinates: { lat: 51.193048, lng: 6.642120 } },
        { coordinates: { lat: 51.183048, lng: 6.742120 } },
        // Add more places here
      ],
      directionsPath: [],
      polylineOptions: {
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
      },
        }
    },
    computed:{
        Center(){
            return this.center
        },
        Markers(){
            return this.markers
        }
    },
async mounted(){
  try{
    const apiKey='AIzaSyA_v6WgXlHfs7pDEN9KtgcsPaPnIOZv4Yg'
        const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${51.193048},${6.642120}&destination=${51.183048},${6.742120}&key=${apiKey}`;
        const response = await axios.get(url);
        return response.data;
      }
      catch(err){
        console.log(err);
        return ''
      }
},

}
</script>

<style scoped>
@media only screen and (max-width: 480px){
    h2{
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size:22px;
        font-weight: 500;
        color: black;
        text-align: center;
        margin-top:20px;
    }
    .map-body{
        width:96vw;
        height:50vh;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    h2{
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size:24px;
        font-weight: 500;
        color: black;
        text-align: center;
        margin-top:20px;
    }
    .map-body{

    }
}
@media only screen and (min-width:961px){
    h2{
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size:32px;
        font-weight: 500;
        color: black;
        text-align: center;
        margin-top:20px;
    }
    .map-body{
        width:60vw;
        height:50vh;
    }
}
</style>